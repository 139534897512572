import React from "react";
import "./App.css";
import Header from "./app/components/Header";
import Hero from "./app/screens/Hero";
import Footer from "./app/components/Footer";
// import About from "./app/screens/About";
import Contact from "./app/screens/Contact";
// import Portfolio from "./app/screens/Portfolio";
import Services from "./app/screens/Services";
import WhyChooseUs from "./app/screens/WhyUs";

const App: React.FC = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Services />
      {/* <Portfolio /> */}
      <WhyChooseUs />
      {/* <About /> */}
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
