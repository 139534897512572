import React from "react";
import "./Contact.css";

const Contact: React.FC = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="contact-content">
          <div className="contact-info">
            <h3>Contact Information</h3>
            <p className="contact-description">
              <b>Let's Work Together</b>
              <br></br>
              <br></br>
              Ready to take your business to the next level? Contact
              AglaonemaTech today to discuss how we can help you achieve your
              goals with our comprehensive technology solutions.
            </p>
            <div className="contact-details">
              <div className="contact-detail">
                <i className="fas fa-phone-alt"></i>
                <p>(+971) 54 783 6151</p>
              </div>
              <div className="contact-detail">
                <i className="fas fa-envelope"></i>
                <p>contact@aglaonematech.com</p>
              </div>
              <div className="contact-detail">
                <i className="fas fa-map-marker-alt"></i>
                <p>Dubai Silicon Oasis, UAE</p>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form action="#" method="post">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Your Message"
                  rows={5}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
