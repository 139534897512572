import React, { useState } from "react";
import Modal from "react-modal";
import {
  CodeBracketIcon,
  DevicePhoneMobileIcon,
  CloudArrowUpIcon,
  AcademicCapIcon,
  ArrowPathIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css"; // Basic styling for tabs
import "./Services.css";

Modal.setAppElement("#root");

const Services: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    service: [],
  });

  const openModal = (title: string, content: string, service: any) => {
    setModalContent({ title, content, service });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const gettingStarted = () => {
    setModalIsOpen(false);
    window.location.href = "#contact";
  };

  return (
    <section id="services" className="services">
      <div className="container">
        <h2 className="section-title">What we do</h2>
        <div className="service-cards">
          <div
            className="service-card"
            onClick={() =>
              openModal(
                "Cutting-Edge Web Development Solutions",
                "In today's digital landscape, your website is more than just an online presence—it's the heart of your brand, the hub of your marketing, and the key to unlocking new opportunities. At Aglaonema Tech, we specialize in creating custom web solutions that not only look great but perform flawlessly, providing your visitors with an intuitive, engaging, and secure experience.",
                [
                  {
                    service: "Custom Website Development",
                    description:
                      "Your business is unique, and your website should reflect that. We build custom websites tailored to your specific needs, ensuring they are scalable, secure, and optimized for performance.",
                  },
                  {
                    service: "Responsive Design",
                    description:
                      "In a mobile-first world, responsive design isn't just an option—it's a necessity. We create websites that look and function perfectly on any device, providing a seamless experience for your users.",
                  },
                  {
                    service: "E-Commerce Solutions",
                    description:
                      "Ready to take your business online? Our e-commerce solutions are designed to maximize conversions, streamline operations, and provide a secure, user-friendly shopping experience.",
                  },
                  {
                    service: "SEO Optimization",
                    description:
                      "A beautiful website is only as good as the traffic it attracts. Our SEO experts ensure your site is optimized for search engines, helping you rank higher and reach more potential customers.",
                  },
                  {
                    service: "Website Maintenance & Support",
                    description:
                      "A website is never truly finished. We offer ongoing maintenance and support services to keep your site secure, updated, and running smoothly.",
                  },
                ]
              )
            }
          >
            <CodeBracketIcon className="service-icon" />
            <h3>Website Development</h3>
            <p>
              Crafting responsive, user-friendly websites tailored to client
              needs, using the latest technologies and best practices to ensure
              optimal performance and accessibility.
            </p>
          </div>
          <div
            className="service-card"
            onClick={() =>
              openModal(
                "Build High-Performance Android and iOS Apps with a Single Codebase Using React Native",
                "In the mobile-first era, having a presence on both Android and iOS is crucial for reaching your audience, but developing separate apps for each platform can be time-consuming and costly. At Aglaonema Tech, we specialize in building high-quality, cross-platform mobile applications using React Native. Our solutions allow you to launch on both Android and iOS simultaneously, reducing time to market and saving development costs without compromising on quality",
                [
                  {
                    service: "Cross-Platform App Development",
                    description:
                      "Leverage the power of React Native to build apps that work seamlessly on both Android and iOS. Our cross-platform approach ensures consistent performance and user experience across devices.",
                  },
                  {
                    service: "Custom App Solutions",
                    description:
                      "Every business has unique needs. We develop tailor-made mobile applications that align with your business goals, offering custom features and designs that set your app apart from the competition.",
                  },
                  {
                    service: "User-Centric Design",
                    description:
                      "A great app starts with a great user experience. Our design team focuses on creating intuitive, user-friendly interfaces that keep your customers engaged and coming back for more.",
                  },
                  {
                    service: "Performance Optimization",
                    description:
                      "Speed and responsiveness are critical in mobile apps. We optimize every aspect of your app, from loading times to smooth animations, ensuring a fast, fluid experience for your users.",
                  },
                  {
                    service: "App Integration",
                    description:
                      "Seamlessly integrate your app with existing systems and services. Whether it’s APIs, payment gateways, or third-party services, we ensure your app connects effortlessly with the tools you rely on",
                  },
                  {
                    service: "Maintenance & Support",
                    description:
                      "The mobile landscape is constantly evolving, and so are your users' needs. We offer ongoing maintenance and support to keep your app up-to-date, secure, and running smoothly.",
                  },
                ]
              )
            }
          >
            <DevicePhoneMobileIcon className="service-icon" />
            <h3>Mobile App Development</h3>
            <p>
              Developing intuitive and high-performing mobile applications
              across platforms, ensuring seamless user experiences and
              leveraging native or cross-platform technologies.
            </p>
          </div>
          <div
            className="service-card"
            onClick={() =>
              openModal(
                "Unlock your team's full potential and drive your business forward",
                "Our comprehensive Training and Development programs are designed to empower your team with the knowledge and skills they need to thrive in an ever-evolving environment.",
                [
                  {
                    service: "Leadership and Management Training",
                    description:
                      "Empower your leaders with the skills to inspire, motivate, and guide their teams to success.",
                  },
                  {
                    service: "Agile Training",
                    description:
                      "Train your teams on Agile methodologies like Scrum, Kanban, SAFe.",
                  },
                  {
                    service: "Soft Skills Enhancement",
                    description:
                      "Improve communication, collaboration, and problem-solving abilities across your organization.",
                  },
                ]
              )
            }
          >
            <AcademicCapIcon className="service-icon" />
            <h3>Training and Workshops</h3>
            <p>
              We are committed to helping your business achieve excellence
              through effective Training and Development. Let us be your partner
              in fostering a culture of continuous improvement and lifelong
              learning.
            </p>
          </div>
          {/* <div
            className="service-card"
            onClick={() => openModal("Agile Transformations", "", [])}
          >
            <ArrowPathIcon className="service-icon" />
            <h3>Agile Transformations</h3>
            <p>
              Guiding organizations through the adoption of Agile methodologies,
              enhancing flexibility, collaboration, and efficiency across teams
              to deliver projects faster and with greater adaptability.
            </p>
          </div>
          <div
            className="service-card"
            onClick={() => openModal("Staffing Solutions", "", [])}
          >
            <UsersIcon className="service-icon" />
            <h3>Staffing Solutions</h3>
            <p>
              Providing expert talent acquisition services to meet specialized
              workforce needs, ensuring the right fit for technical, managerial,
              and strategic roles across industries.
            </p>
          </div> */}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Service Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <h2>{modalContent.title}</h2>
          <p>{modalContent.content}</p>

          {modalContent.service.length > 0 && (
            <Tabs>
              <TabList>
                {modalContent.service.map((item: any, index) => (
                  <Tab key={index}>{item.service}</Tab>
                ))}
              </TabList>

              {modalContent.service.map((item: any, index) => (
                <TabPanel key={index}>
                  <h3>{item.service}</h3>
                  <p>{item.description}</p>
                </TabPanel>
              ))}
            </Tabs>
          )}

          <div className="modal-footer">
            <button className="cta-button" onClick={closeModal}>
              Close
            </button>
            <button className="cta-button primary" onClick={gettingStarted}>
              Get Started
            </button>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default Services;
