import React from "react";
import {
  AcademicCapIcon,
  LightBulbIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import "./WhyUs.css"; // Import the updated CSS file

const WhyChooseUs = () => {
  return (
    <section id="whyus" className="why-choose-us-grid">
      <h2 className="why-choose-us-heading">
        Why Choose Us as Your Digital Partner?
      </h2>
      <div className="why-choose-us-grid-container">
        <div className="why-choose-us-item">
          <h3 className="why-choose-us-subheading">
            Unmatched Expertise & Innovation
          </h3>
          <p className="why-choose-us-text">
            With years of industry experience and a relentless passion for
            innovation, our engineers lead the way by harnessing the latest
            technologies and implementing cutting-edge best practices. We don't
            just follow trends; we set them.
          </p>
        </div>
        <div className="why-choose-us-item">
          <h3 className="why-choose-us-subheading">
            A Truly YOU-Centric Approach
          </h3>
          <p className="why-choose-us-text">
            Your success is our mission. We collaborate closely with you
            throughout every phase of development, ensuring the final product
            not only meets but exceeds your business objectives. Your goals are
            our goals.
          </p>
        </div>
        <div className="why-choose-us-item">
          <h3 className="why-choose-us-subheading">
            All-Inclusive Solutions for Your Business Needs
          </h3>
          <p className="why-choose-us-text">
            From initial concept to final launch, we provide end-to-end
            solutions in web development, mobile app development, training, and
            staffing services. But we don’t stop there. Our dedication to your
            success continues with reliable post-launch support, keeping your
            business agile and up-to-date in a rapidly evolving market.
          </p>
        </div>
        <div className="why-choose-us-item">
          <h3 className="why-choose-us-subheading">
            Proven Success, Proven Results
          </h3>
          <p className="why-choose-us-text">
            Our track record speaks volumes. We've empowered countless clients
            to achieve their digital dreams with bespoke solutions that deliver
            measurable results.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
