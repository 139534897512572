import React from "react";
import "./Header.css";
import logoImage from "../../Assets/images/logo.png"; // Adjust the path as needed

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <img src={logoImage} alt="Aglaonema Tech Logo" />
          <h1>Aglaonema Tech</h1>
        </div>
        <nav className="nav">
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#services">Services</a>
            </li>
            {/* <li>
              <a href="#portfolio">Portfolio</a>
            </li> */}
            <li>
              <a href="#whyus">Why Us</a>
            </li>
            {/* <li>
              <a href="#about">About Us</a>
            </li> */}
          </ul>
        </nav>
        <div className="actions">
          <a href="#contact" className="contact-btn">
            Contact Us
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
