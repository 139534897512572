import React from "react";
import "./Hero.css";

const Hero: React.FC = () => {
  return (
    <section id="home" className="hero">
      <div className="container">
        <h2>
          Empowering Your Digital Transformation with Cutting-Edge Solutions and
          Agile Expertise
        </h2>
        <p>
          We specialize in delivering End-to-End Digital Transformation with
          Proven Industry Expertise and Agile Excellence.
        </p>
        <a href="#services" className="btn">
          Learn More
        </a>
      </div>
    </section>
  );
};

export default Hero;
